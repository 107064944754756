"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateImageSrcset = void 0;
var defaultImageWidths = {
  xs: 320,
  sm: 640,
  md: 960,
  lg: 1280,
  xl: 1600,
  xxl: 1920,
  xxxl: 3840
};
var generateImageSrcset = exports.generateImageSrcset = function generateImageSrcset(_ref) {
  var image = _ref.image,
    _ref$widths = _ref.widths,
    widths = _ref$widths === void 0 ? ['sm', 'md', 'lg'] : _ref$widths,
    _ref$existingTransfor = _ref.existingTransformation,
    existingTransformation = _ref$existingTransfor === void 0 ? 't_base_vehicle_transformation' : _ref$existingTransfor,
    _ref$updatedTransform = _ref.updatedTransformation,
    updatedTransformation = _ref$updatedTransform === void 0 ? 't_locator_vehicle_transformation' : _ref$updatedTransform;
  var imageIncludesTransformation = image.includes(existingTransformation);
  if (!imageIncludesTransformation) {
    return undefined;
  }
  return widths.reduce(function (previousValue, width, key) {
    var widthValue = defaultImageWidths[width];
    var newValue = width === 'xxxl' ? image.replace(existingTransformation, updatedTransformation) : image.replace(existingTransformation, "".concat(updatedTransformation, ",w_").concat(widthValue));
    return key === 0 ? "".concat(newValue, " ").concat(widthValue, "w") : "".concat(previousValue, ", ").concat(newValue, " ").concat(widthValue, "w");
  }, '');
};