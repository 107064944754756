"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.localeFormattedDate = exports.dateValidityCheck = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/* eslint-disable import/prefer-default-export */
var localeFormattedDate = exports.localeFormattedDate = function localeFormattedDate(date, locale, options) {
  // Hard coding the time zone to UTC indicator to the date string prevents a bug where Date will sometimes modify the date passed into it
  // by changing the date based on the users timezone, this means for people in a timezone behind the UTC the
  // date will change for them.
  var dateWithoutTime = date ? date === null || date === void 0 ? void 0 : date.split('T')[0] : null;
  return new Date(dateWithoutTime).toLocaleDateString(locale.replace('_', '-'), _objectSpread({
    timeZone: 'UTC'
  }, options));
};
var dateValidityCheck = exports.dateValidityCheck = function dateValidityCheck(date) {
  var sanitisedDate = date && date.trim();
  return /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/.test(sanitisedDate);
};