"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useVehiclePrices = exports["default"] = void 0;
var _reactRedux = require("react-redux");
var _helpers = require("../shared/currencyConversion/helpers");
var _numbers = require("../shared/localisation/numbers");
var _settings = require("../shared/selectors/settings");
var useVehiclePrices = exports.useVehiclePrices = function useVehiclePrices(vehicle, translations, handleZeroPriceAsText) {
  var marketInfo = (0, _reactRedux.useSelector)(function (state) {
    return (0, _settings.getAVLConfig)(state);
  });
  var locale = vehicle.locale ? vehicle.locale.replace('_', '-') : marketInfo.locale.replace('_', '-');
  var grossPrice = (0, _helpers.handleVehiclePrice)(vehicle.price.value, {
    currencyType: vehicle.price.currency,
    locale: locale,
    handleZeroPriceAsText: handleZeroPriceAsText,
    priceSpecial: vehicle.priceSpecial,
    translationsSold: translations.sold,
    vehiclePrice: vehicle.price.value,
    zeroPriceText: translations.zeroPriceText
  });
  var netPrice = vehicle.netPrice ? (0, _helpers.handleVehiclePrice)(vehicle.netPrice, {
    currencyType: vehicle.price.currency,
    locale: locale,
    handleZeroPriceAsText: handleZeroPriceAsText,
    priceSpecial: vehicle.priceSpecial,
    zeroPriceText: translations.zeroPriceText,
    translationsSold: translations.sold,
    vehiclePrice: vehicle.price.value
  }) : undefined;
  var basePrice = vehicle.basePrice ? (0, _helpers.handleVehiclePrice)(vehicle.basePrice, {
    currencyType: vehicle.price.currency,
    locale: locale,
    handleZeroPriceAsText: handleZeroPriceAsText,
    priceSpecial: vehicle.priceSpecial,
    zeroPriceText: translations.zeroPriceText,
    translationsSold: translations.sold,
    vehiclePrice: vehicle.price.value
  }) : undefined;
  var expenses = vehicle.expenses ? (0, _helpers.handleVehiclePrice)(vehicle.expenses, {
    currencyType: vehicle.price.currency,
    locale: locale,
    handleZeroPriceAsText: handleZeroPriceAsText,
    priceSpecial: vehicle.priceSpecial,
    zeroPriceText: translations.zeroPriceText,
    translationsSold: translations.sold,
    vehiclePrice: vehicle.price.value
  }) : undefined;
  var grossPriceNumber = Number(vehicle.price.value);
  var taxNumber = !!grossPriceNumber && !!netPrice ? grossPriceNumber - Number(vehicle.netPrice) : 0;
  var taxPrice = (0, _numbers.localiseCurrency)(taxNumber, locale, vehicle.price.currency, 0);
  return {
    grossPrice: grossPrice,
    netPrice: netPrice,
    taxPrice: taxPrice,
    taxNumber: taxNumber,
    basePrice: basePrice,
    expenses: expenses
  };
};
var _default = exports["default"] = useVehiclePrices;