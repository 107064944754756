"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = UserCentrics;
var _react = require("react");
var _reactRedux = require("react-redux");
function UserCentrics() {
  var userCentricsKey = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.analytics.userCentricsKey;
  });
  (0, _react.useEffect)(function () {
    if (userCentricsKey) {
      var userCentricsScript = document.createElement('script');
      userCentricsScript.setAttribute('id', 'usercentrics-cmp');
      userCentricsScript.setAttribute('data-settings-id', userCentricsKey);
      userCentricsScript.src = "https://app.usercentrics.eu/browser-ui/latest/loader.js";
      userCentricsScript.async = true;
      document.head.appendChild(userCentricsScript);
      userCentricsScript.onload = function () {
        var userCentricsReloadScript = document.createElement('script');
        userCentricsReloadScript.type = "application/javascript";
        userCentricsReloadScript.src = "https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js";
        document.head.appendChild(userCentricsReloadScript);
        var ucReloadOnOptIn = document.createElement('script');
        ucReloadOnOptIn.text = "\n          window.addEventListener('UC_UI_INITIALIZED', function(event) {\n            uc.blockElements({\n              S1pcEj_jZX: '#sticky-search, #retailer-map-wrapper, [class*=\"SearchResults__Grid\"]'\n            });\n\n            const disabledProviders = uc.getDisabledProviders();\n            const isGoogleMapsDisabled = disabledProviders.some(\n              provider => provider.pid === 'S1pcEj_jZX',\n            );\n\n            if (isGoogleMapsDisabled){\n              UC_UI.acceptService('S1pcEj_jZX');\n              UC_UI.rejectService('S1pcEj_jZX');\n            }\n\n            // Reload the page only once when opting in\n            uc.reloadOnOptIn('S1pcEj_jZX');\n          });\n        ";
        document.head.appendChild(ucReloadOnOptIn);
        var ucDeactivateBlocking = document.createElement('script');
        ucDeactivateBlocking.text = "\n          window.addEventListener('UC_UI_INITIALIZED', function(event) {\n            uc.deactivateBlocking([\n              'BJ59EidsWQ',\n              'HkocEodjb7',\n              'B1Hk_zoTX',\n              'S1_9Vsuj-Q',\n            ]);\n          });\n        ";
        document.head.appendChild(ucDeactivateBlocking);
        var ucCustomStyles = document.createElement('style');
        ucCustomStyles.textContent = "\n          .uc-embedding-container {\n            backdrop-filter: blur(3px)!important;\n            z-index: 9999!important;\n          }\n          [class*=\"StickyBottomSearchBarWrapper\"] .uc-embedding-container {\n            backdrop-filter: none!important;\n          }\n          .uc-embedding-wrapper {\n            text-transform: none!important;\n          }\n          .uc-embedding-container .uc-embedding-buttons .uc-embedding-accept {\n            background-color: #335b4c;\n          }";
        document.head.appendChild(ucCustomStyles);
      };
    }
  }, [userCentricsKey]);
  return null;
}